module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-gatsby-cloud@5.12.2_encoding@0.1.13_gatsby@5.12.11_@types+webpack@4.41.38_babel_z53zdguayknontryrcgh6jpqpu/node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-react-axe@0.5.0_gatsby@5.12.11_@types+webpack@4.41.38_babel-eslint@10.1.0_eslin_7f3rj7z4wdcv6sy3b6be6znqly/node_modules/gatsby-plugin-react-axe/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-catch-links@5.12.0_gatsby@5.12.11_@types+webpack@4.41.38_babel-eslint@10.1.0_es_czekcr5imu7vczz7mcbxvyero4/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-scroll-reveal@0.0.7_gatsby@5.12.11_@types+webpack@4.41.38_babel-eslint@10.1.0_e_ghcezhb6ukxikgllpp3rtgw3vi/node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-scroll-reveal@0.0.7_gatsby@5.12.11_@types+webpack@4.41.38_babel-eslint@10.1.0_e_ghcezhb6ukxikgllpp3rtgw3vi/node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[],"threshold":1,"once":true,"disable":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-launchdarkly@1.0.0_gatsby@5.12.11_@types+webpack@4.41.38_babel-eslint@10.1.0_es_mj6emcw4eiurthsve3pjzor6nu/node_modules/gatsby-plugin-launchdarkly/gatsby-browser.js'),
      options: {"plugins":[],"clientSideID":"66ccb5f628f86a0fa634d4bf","options":{"bootstrap":"localStorage"},"user":{"key":"anonymous-user","anonymous":true}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-tagmanager@5.12.3_gatsby@5.12.11_@types+webpack@4.41.38_babel-eslint@10._7xkcsym22ro5qzxb4n3g6yefxi/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KJSM5X","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.12.11_@types+webpack@4.41.38_babel-eslint@10.1.0_eslint@8.54.0__encoding@0.1.13_reac_rliabc5qrkgj3bk5yjf2kiu7mi/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
