import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';
import CryptoJS from 'crypto-js';

import { getIdToken } from '../global/auth';

function generateRandomNonce(length) {
  if (typeof window !== 'undefined') {
    let tempNonce = new Uint8Array(length);
    crypto.getRandomValues(tempNonce);

    return Array.from(tempNonce)
      .map((byte) => byte.toString(16).padStart(2, '0'))
      .join('');
  } else {
    return new Date().getTime().toString();
  }
}

const httpLink = createHttpLink({
  uri: process.env.ACCOUNT_API_URI,
  headers: {
    'x-api-key': process.env.ACCOUNT_API_KEY
  }
});

const authLink = setContext((_, { headers }) => {
  const token = getIdToken();
  const nonce = generateRandomNonce(16);
  const API_SECRET = CryptoJS.enc.Utf8.parse(
    process.env.API_SIGNATURE
  ).toString();

  const hmac = CryptoJS.HmacSHA256(nonce, API_SECRET);

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
      'x-nonce': nonce,
      'x-signature': hmac
    }
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});
