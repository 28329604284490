import { createContext, Component } from 'react';
import PropTypes from 'prop-types';
import { silentAuth, isAuthDisabled } from '../../global/auth';

export const SilentlyLoggedIn = createContext(false);
export const IsLoggingIn = createContext(false);

export default class SessionCheck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      silentlyLoggedIn: false,
      loading: true
    };

    this.handleCheckSession = this.handleCheckSession.bind(this);
  }

  handleCheckSession(isLoggedIn) {
    // The silent authentication flow will return whether or not the user
    // was successfully logged in. If they were successfully logged in, update
    // state so we can update the SilentlyLoggedIn context consumers.
    this.setState({ silentlyLoggedIn: isLoggedIn, loading: false });
  }

  componentDidMount() {
    // Run the silent authentication method, and when it finishes, call
    // this.handleCheckSession
    // Don't run check session if the page is callback or auth is disabled.
    if (!isAuthDisabled && !window.location.pathname.includes('/callback')) {
      silentAuth(this.handleCheckSession);
    }
  }

  render() {
    return (
      /* For the silent authentication flow, we want to let login happen in the
      background, and then individual page components update when the user has
      been logged in. */
      <SilentlyLoggedIn.Provider value={this.state.silentlyLoggedIn}>
        <IsLoggingIn.Provider value={this.state.loading}>
          {this.props.children}
        </IsLoggingIn.Provider>
      </SilentlyLoggedIn.Provider>
    );
  }
}

SessionCheck.propTypes = {
  children: PropTypes.node.isRequired
};
